import React, { useState, Component } from "react"
import { graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getShopifyImage } from 'gatsby-source-shopify'

import Seo from "../components/seo"
import Link from '../utils/link'
import ProductCard from '../components/product-card'

class ProductTemplate extends Component {

  state = {
    variant: 0,
    modal: false,
    select: false,
    image: null,
    activeItem: null,
    offSize: false
  }

  settings = {
    ref: 'slider',
    infinite: true,
    speed: 600,
    fade:true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    cssEase: 'ease',
    pauseOnHover: false,
    focusOnSelect: false,
    adaptiveHeight: true,
  }

  _toggleSize = () => {
    this.setState({ offSize: !this.state.offSize })
  }


  // handleItemClick(index) {
  //   this.setState({
  //     activeItem: index,
  //   })
  // }

  render() {
    const product = this.props.data.shopifyProduct
    //const related = this.props.data.allShopifyProduct.edges
    let { variant, modal, select, offSize } = this.state

    const addToCart = () => {
      this.props.addVariantToCart(product.variants[variant].shopifyId, 1)
      this.setState({ modal: true })
    }

    //const ingredients = product.metafields.find(el => el.key === 'ingredients')
    const details = product.metafields.find(el => el.key === 'details')
    const sizeguide = product.metafields.find(el => el.key === 'size_guide')
    const size = product.metafields.find(el => el.key === 'size')


    { console.log(product.metafields) }
    { console.log(sizeguide) }

    // const _selectVariant = (v) => {
    //   setVariant(v)
    // }


    // const variants = product.variants.map((el, i) => {
    //   let { title } = el

    //   let props = {
    //     key: i,
    //     className: `btn btn--variation ${el.shopifyId === variant.shopifyId ? 'active' : ''}`,
    //     onClick: () => _selectVariant(el),
    //   }
    //   return (<button {...props}>{ title }</button>)
    // }).filter(n => n)

    return (
      <>
        <Seo title={he.decode(product.title)} description={he.decode(product.description)} bodyClass='shop single-product'  />
        <section className='product-single'>
          <div className='product-single__inner'>

            <div className='product-single__col'>

              <Fade bottom distance='40px'>

                {/* <div className='product-single__mobile'>
                  <div className='product-single__brand'>{ product.vendor }</div>
                  <h2>{ product.title }</h2>
                </div> */}

                <div className='product-single__gallery'>

                  {/* { image ? <GatsbyImage image={product.featureImage} alt={product.title} onClick={() => this.setState({ image: null })} /> : */}
                    {/* // <Slider {...this.settings}> */}
                      { product.media.map((el, i) => {
//                        console.log(el.preview)
                        return (
                          <div className='product-single__gallery-item'>
                            <GatsbyImage  key={i} loading='lazy' image={getImage(el.preview.image)} alt={product.title} />
                          </div>
                        )
                      }) }
                    {/* </Slider> */}
                  {/* } */}
                </div>
              </Fade>

            </div>

            <div className='product-single__col'>


              <div className='product-single__content'>
                <div className='sticky'>
                  {/* <div className='product-single__brand'>{ product.vendor }</div> */}
                  <h1>{ product.title }</h1>

                  <div className='product-single__price'>{ size ? size.value + ' /' : '' } £{ parseInt(product.variants[variant].price) }</div>
                  <div className='product-single__vat'>Price includes VAT</div>

                  {/* { product.variants?.length > 1 &&
                    <div className='product-single__variant'>{ product.variants[variant].selectedOptions[0].value }</div>
                  } */}

                { product.variants?.length > 1 &&
                  <div className='product-single__select'>
                     <div className='product-single__size-container'>
                      <span>Select a { product.variants[variant].selectedOptions[0].name }</span>

                        <div className='product-single__size-guide'>
                          <button onClick={this._toggleSize} className=''>Size Guide</button>
                        </div>
                    </div>

                    <ul>
                    { product.variants.map((el, i) => (
                      <li 
                      key={i} 
                      className={this.state.activeItem === i ? 'active' : ''}
                      onClick={() => this.setState({variant: i, select: false, image: el.image, select: !select, activeItem: i,})}>
                        { el.selectedOptions[0].value }
                        </li>
                    )) }
                    </ul>
                  </div>
                }
                  <button type='button' onClick={addToCart} className='add-to-cart'>Add to Bag</button>

                  { details &&
                  <div>
                    <h2 className='product-single__description'>Details</h2>
                    <div className='product-single__desc'>
                      <p dangerouslySetInnerHTML={{ __html: details.value }} />
                    </div>
                  </div>
                  }

                  {/* <div className='product-single__desc' dangerouslySetInnerHTML={{__html: product.descriptionHtml }} /> */}

                  <h2 className='product-single__description'>Description</h2> 
                  <div className='product-single__desc' dangerouslySetInnerHTML={{__html: product.descriptionHtml }} />

                </div>


                {/* { related?.length > 0 &&
                  <section className='product-single__related'>
                    <div className='product-single__related-title'>If you’re interested in this,<br /> we’d also recommend:</div>
                    <div className='product-single__related-products'>
                      { related.map((el, i) => <ProductCard key={i} {...el.node} />) }
                    </div>
                  </section>
                } */}

                <section className={`product-single__modal ${modal ? 'active' : ''}`}>
                  <div>
                    <p>{ product.title } has been added to your bag</p>
                    <div className='product-single__modal__image'>
                      <GatsbyImage image={product.featureImage} alt={product.title} />
                    </div>
                    <div className='product-single__modal__buttons'>
                      <button type='button' onClick={() => this.setState({ modal: false })}>
                        Continue Shopping
                      </button>
                      <Link to='/cart'>
                        Checkout
                      </Link>
                    </div>
                  </div>
                </section>

              </div>

            </div>

          </div>
        </section>

        <div className={ offSize ? 'size-overlay active' : 'size-overlay' }>
          <div className='size-overlay__inner'>
            <div className='size-overlay__close'>
              <button onClick={this._toggleSize}>

                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8867 0L9.00264 6.88406L2.11858 0L0 2.11858L6.88406 9.00264L0 15.8867L2.11858 18.0053L9.00264 11.1212L15.8867 18.0053L18.0053 15.8867L11.1212 9.00264L18.0053 2.11858L15.8867 0Z" fill="black"/>
                </svg>

              </button>
            </div>

            <div className='size-overlay__guide'>
              <h4>Size Guide</h4>
              <img src='https://cdn.shopify.com/s/files/1/0561/6218/9402/files/size_guide.jpg?v=1653968249' alt='Size Guide' />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!, $vendor: String!) {
    shopifyProduct(id: {eq: $id}) {
      description
      descriptionHtml
      handle
      title
      vendor
      metafields {
        key
        namespace 
      }
      variants {
        price
        shopifyId : storefrontId
        title
        selectedOptions {
          name
          value
        }
      }
      media {
        id
        preview {
          image {
            src
            originalSrc
            gatsbyImageData(
              width: 1800
              formats: [AUTO, WEBP]
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
    allShopifyProduct(
      limit: 4,
      filter: {
        vendor: { eq: $vendor },
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          handle
          featuredImage {
            originalSrc
            width
            height
          }
          title
          vendor
          variants {
            shopifyId : storefrontId
            price
          }
        }
      }
    }
  }
`


export default ProductTemplate
